{
    "common": {
        "mon": "الاثنين",
        "tue": "الثلاثاء",
        "wed": "الأربعاء",
        "thu": "الخميس",
        "fri": "الجمعة",
        "sat": "السبت",
        "sun": "الأحد",
        "jan": "يناير",
        "feb": "فبراير",
        "mar": "مارس",
        "apr": "أبريل",
        "jun": "يونيو",
        "jul": "يوليو",
        "aug": "أغسطس",
        "sep": "سبتمبر",
        "oct": "أكتوبر",
        "nov": "نوفمبر",
        "dec": "ديسمبر",
        "january": "يناير",
        "february": "فبراير",
        "march": "مارس",
        "april": "أبريل",
        "may": "مايو",
        "june": "يونيو",
        "july": "يوليو",
        "august": "أغسطس",
        "september": "سبتمبر",
        "october": "أكتوبر",
        "november": "نوفمبر",
        "december": "ديسمبر",
        "checkin": "تسجيل الوصول",
        "checkout": "تسجيل المغادرة",
        "room": "غرفة",
        "rooms": "غرف",
        "adult": "بالغ",
        "adults": "بالغون",
        "edit": "تعديل",
        "editCap": "تعديل",
        "delete": "حذف",
        "deleteCap": "حذف",
        "localTime": "الوقت المحلي",
        "back": "عودة",
        "home": "الرئيسية",
        "yes": "نعم",
        "no": "لا",
        "left": "متبقي",
        "hour": "ساعة",
        "hours": "ساعات",
        "night": "ليلة",
        "nights": "ليالي",
        "for": "لـ",
        "why": "لماذا",
        "get": "احصل على",
        "more": "المزيد",
        "agree": "موافق",
        "disagree": "غير موافق",
        "basedOn": "بناءً على",
        "error": "حدث خطأ، الرجاء المحاولة مرة أخرى لاحقاً",
        "error2": "فشل، حاول مرة أخرى!",
        "error3": "حدث خطأ!!",
        "error4": "حدث خطأ!! الرجاء المحاولة مرة أخرى",
        "hi": "مرحبا",
        "to": "إلى",
        "free": "مجاني",
        "per": "لكل",
        "at": "في",
        "day": "يوم",
        "days": "أيام" ,
        "time": "وقت",
        "of": "من",
        "photo":  "صورة",
        "photos":  "صور",
        "done": "تم",
        "fullStay": "الإقامة الكاملة",
        "date": "تاريخ",
        "bookingDetail":  "تفاصيل الحجز",
        "Back": "رجوع",
        "Home": "الرئيسية"
    },
    "userPopup": {
        "myAccount": "حسابي",
        "welcome": "مرحبا",
        "user": "المستخدم",
        "findMyBookings": "ابحث عن حجوزاتي",
        "viewProfile": "عرض الملف الشخصي",
        "makeAReservarion": "قم بالحجز",
        "logout": "تسجيل الخروج",
        "loginCap": "تسجيل الدخول",
        "login": "تسجيل الدخول",
        "invalidCredentials": "بيانات اعتماد غير صالحة",
        "invalidEmail": "البريد الإلكتروني غير صالح",
        "passwordIsRequired": "كلمة المرور مطلوبة",
        "minChar8": "الحد الأدنى 8 أحرف",
        "tooShort": "قصير جدا!",
        "tooLong": "طويل جدا!",
        "required": "مطلوب",
        "emailInvalid": "أدخل بريد إلكتروني صالح",
        "passwordInvalid": "أدخل كلمة مرور صالحة",
        "phNumInvalid": "رقم الهاتف غير صالح",
        "signupCap": "إنشاء حساب",
        "signup": "إنشاء حساب",
        "verifyText": "تحقق من أنك أنت",
        "verifyText2": "لقد أرسلنا رمز تحقق إلى بريدك الإلكتروني",
        "verificationCode": "رمز التحقق",
        "didNotReceiveEmail": "لم تتلق البريد الإلكتروني",
        "clickToResend": "انقر لإعادة الإرسال",
        "verifyAndSignIn": "تحقق وسجل الدخول",
        "otpSentSuccessfully": "تم إرسال OTP بنجاح",
        "somethingWentWrong": "حدث خطأ، الرجاء المحاولة مرة أخرى",
        "otpError": "الرجاء إدخال رقم صالح",
        "otpError2": "الرجاء إدخال أرقام فقط",
        "otpError3": "OTP غير صحيح، الرجاء المحاولة مرة أخرى",
        "otpError4": "انتهت صلاحية OTP، انقر إعادة الإرسال للمحاولة مرة أخرى",
        "otpError5": "يرجى المحاولة مرة أخرى",
        "forgetPassword": "نسيت كلمة المرور",
        "submitError": "المستخدم غير موجود!",
        "userExistError": "المستخدم موجود بالفعل",
        "checkYourEmail": "تحقق من بريدك الإلكتروني",
        "passwordResetLinkSent": "لقد أرسلنا رابط إعادة تعيين كلمة المرور إلى",
        "noWorriesResetInstructions": "لا تقلق، سنرسل لك تعليمات إعادة التعيين.",
        "enterYourEmail": "أدخل بريدك الإلكتروني",
        "resetMyPassword": "إعادة تعيين كلمة المرور",
        "setANewPassword": "قم بتعيين كلمة مرور جديدة",
        "newPassword": "كلمة مرور جديدة",
        "confirmPassword": "تأكيد كلمة المرور",
        "confirmPasswordError": "يجب أن تتطابق كلمات المرور",
        "backToLogin": "العودة إلى تسجيل الدخول",
        "dontHaveAccount": "لا تملك حساباً؟",
        "alreadyHaveAccount": "هل لديك حساب بالفعل؟",
        "memberDiscount1": "ستحصل على",
        "memberDiscount2": "خصم عضو إلى الأبد.",
        "discount1": "أدر جميع حجوزاتك من مكان واحد.",
        "discount2": "اشترك للحصول على توصيات مخصصة وتجارب مصممة خصيصاً!!",
        "discount3": "انضم إلى مجتمع ضيوفنا السعداء وابدأ في تخطيط إقامتك.",
        "leftGridText1": "أدر جميع حجوزاتك من مكان واحد",
        "leftGridText2": "اشترك للحصول على توصيات مخصصة وتجارب مصممة خصيصاً!!",
        "leftGridText3": "انضم إلى مجتمع ضيوفنا السعداء وابدأ في تخطيط إقامتك",
        "gridDiscountText1": "خصم عضو على",
        "gridDiscountText2": "عقارات مختارة إلى الأبد",
        "gridDiscountText3": "سجل الدخول لتجربة مصممة خصيصاً إلى الأبد",
        "gridDiscountText4": "احصل على فوائد عضو حصرية",
        "findBookingError": "البريد الإلكتروني ومعرف الحجز الخاص بك لا يتطابقان",
        "verifyAndConfirmBooking": "تحقق وأكد الحجز",
        "confirmEmailText": "لقد أرسلنا رمز تحقق إلى عنوان بريدك الإلكتروني",
        "enterCodeText": "يرجى إدخال رمز التحقق المرسل إليك",
        "didNotRecieveEmail": "لم تتلق البريد الإلكتروني؟",
        "changeEmailText": "انقر هنا لتغيير عنوان البريد الإلكتروني",
        "verifyAndConfirm": "تحقق وأكد",
        "verified": "تم التحقق",
        "bookingExpired": "انتهت صلاحية الحجز",
        "bookingExpiredText": "نأسف، معرف الحجز الذي تستخدمه قد انتهت صلاحيته",
        "backToFindingBooking": "العودة إلى العثور على الحجز",
        "findMyBooking": "العثور على حجوزاتي",
        "bookingEmail": "البريد الإلكتروني للحجز",
        "reservationId": "معرف الحجز",
        "findBooking": "العثور على الحجز",
        "abandonmentCartDoneText1": "شكراً لك!",
        "abandonmentCartDoneText2": "تم إرسال تفاصيل سلة التسوق إلى بريدك الإلكتروني. يمكنك متابعة الحجز في أي وقت.",
        "Recommended Currencies": "العملات الموصى بها",
        "All currencies": "جميع العملات",
        "thisIsRequiredField": "هذا الحقل مطلوب"
    },
    "widget": {
        "destination": "الوجهة",
        "enterDestination": "أدخل الوجهة",
        "guests": "الضيوف",
        "selectedRate": "السعر المختار",
        "search": "بحث",
        "ages": "الأعمار",
        "orAbove": "أو أكثر",
        "child": "طفل",
        "children": "الأطفال",
        "addAnotherRoom": "أضف غرفة أخرى",
        "removeRoom": "أزل غرفة",
        "property": "الملكية",
        "city": "مدينة",
        "country": "دولة",
        "bookNow": "احجز الآن",
        "destinationIsRequired": "الوجهة مطلوبة",
        "checkInIsRequired": "تاريخ تسجيل الوصول مطلوب",
        "checkOutIsRequired": "تاريخ تسجيل المغادرة مطلوب"
    },
    "promotion": {
        "headingText": "معلومات هامة لـ",
        "regularRate": "السعر العادي",
        "seniorCitizen": "سعر كبار السن",
        "agencyRate": "سعر الوكالة",
        "caaRate": "سعر CAA/AAA",
        "corporateRate": "السعر الخاص بالشركات",
        "governmentRate": "سعر الحكومة/الجيش",
        "promotionalSpecialRate": "السعر الترويجي/الخاص",
        "regularRateDescription": "الأسعار العادية هي أقل الأسعار المتاحة حاليًا لحجز غرفة فندق.",
        "seniorCitizenRateDescription": "هذا سعر فندقي مخفض متاح لكبار السن، عادة لمن هم بعمر 60 عامًا أو أكثر.",
        "agencyRateDescription": "هذا سعر فندقي مخفض يمكن الوصول إليه فقط من خلال وكلاء سفر محددين لتعزيز الشراكة والتعاون.",
        "caaRateDescription": "هذا سعر فندقي خاص مقدم لأعضاء CAA أو AAA لتحقيق توفير حصري.",
        "corporateRateDescription": "هذا سعر فندقي مخفض مخصص للمسافرين من رجال الأعمال، يتم التفاوض عليه عادة بين الفندق والشركة.",
        "governmentRateDescription": "يوفر هذا السعر خصومات محددة لموظفي الحكومة/الجيش عند حجز غرفة فندقية.",
        "contentSeniorCitizen": "يجب تقديم إثبات تاريخ الميلاد عند تسجيل الوصول. عدم تقديم بطاقة هوية صالحة سيؤدي إلى فرض الأسعار السائدة.",
        "contentAgencyRate": "تأكد من وجود تأكيد الحجز وبطاقة الهوية عند الوصول. عدم تقديم هذه الوثائق سيؤدي إلى تطبيق الأسعار العادية.",
        "contentPromotionalSpecialRate": "هذا سعر فندقي مخفض متاح لفترة محدودة، ويرتبط غالبًا بأحداث معينة أو مواسم أو حملات تسويقية.",
        "contentCaaRate": "يرجى التأكد من حمل بطاقة عضوية CAA أو بطاقة هوية مناسبة عند تسجيل الوصول. عدم تقديم إثبات الهوية سيؤدي إلى فرض الأسعار السائدة.",
        "contentCorporateRate": "يجب تقديم بطاقة تعريف الشركة أو أي مستند رسمي آخر عند تسجيل الوصول. عدم تقديم إثبات مناسب سيؤدي إلى فرض الأسعار السائدة.",
        "contentGovernmentRate": "يجب تقديم بطاقة هوية حكومية أو عسكرية صالحة عند تسجيل الوصول. عدم تقديم هذه البطاقة سيؤدي إلى فرض الأسعار العادية.",
        "CORP": "CORP",
        "AGEN": "AGEN",
        "PROM": "PROM",
        "apply": "تطبيق",
        "Offer Code": "رمز العرض",
        "Agency Code": "رمز الوكالة",
        "Corporate Code": "رمز الشركة",
        "All-Access Rate": "سعر الوصول الكامل"       
    }
}