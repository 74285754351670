{
    "common": {
        "mon": "Lun",
        "tue": "Mar",
        "wed": "Mer",
        "thu": "Jeu",
        "fri": "Ven",
        "sat": "Sam",
        "sun": "Dim",
        "jan": "Janv",
        "feb": "Févr",
        "mar": "Mars",
        "apr": "Avril",
        "jun": "Juin",
        "jul": "Juil",
        "aug": "Août",
        "sep": "Sept",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Déc",
        "january": "Janvier",
        "february": "Février",
        "march": "Mars",
        "april": "Avril",
        "may": "Mai",
        "june": "Juin",
        "july": "Juillet",
        "august": "Août",
        "september": "Septembre",
        "october": "Octobre",
        "november": "Novembre",
        "december": "Décembre",
        "checkin": "Arrivée",
        "checkout": "Départ",
        "room": "Chambre",
        "rooms": "Chambres",
        "adult": "Adulte",
        "adults": "Adultes",
        "edit": "Modifier",
        "editCap": "MODIFIER",
        "delete": "Supprimer",
        "deleteCap": "SUPPRIMER",
        "localTime": "Heure locale",
        "back": "RETOUR",
        "home": "ACCUEIL",
        "yes": "OUI",
        "no": "NON",
        "left": "Restant",
        "hour": "Heure",
        "hours": "Heures",
        "night": "nuit",
        "nights": "nuits",
        "for": "pour",
        "why": "Pourquoi",
        "get": "Obtenir",
        "more": "Plus",
        "agree": "ACCEPTER",
        "disagree": "REFUSER",
        "basedOn": "basé sur",
        "error": "Une erreur s'est produite, veuillez réessayer plus tard",
        "error2": "Échec, réessayez!",
        "error3": "Une erreur est survenue!!",
        "error4": "Une erreur s'est produite !! Veuillez réessayer",
        "hi": "Bonjour",
        "to": "à",
        "free": "Gratuit",
        "per": "Par",
        "at": "à",
        "day": "Jour",
        "days": "Jours",
        "time": "Temps",
        "of": "de",
        "photo": "Photo",
        "photos": "Photos",
        "done": "Terminé",
        "fullStay": "Séjour complet",
        "date": "Date",
        "bookingDetail": "Détail de la réservation",
        "Back": "Retour",
        "Home": "Accueil"
    },
    "userPopup": {
        "myAccount": "Mon Compte",
        "welcome": "Bienvenue",
        "user": "Utilisateur",
        "findMyBookings": "TROUVER MES RÉSERVATIONS",
        "viewProfile": "VOIR LE PROFIL",
        "makeAReservarion": "FAIRE UNE RÉSERVATION",
        "logout": "DÉCONNEXION",
        "loginCap": "CONNEXION",
        "login": "Connexion",
        "invalidCredentials": "Identifiants invalides",
        "invalidEmail": "Email invalide",
        "passwordIsRequired": "Mot de passe requis",
        "minChar8": "minimum 8 caractères",
        "tooShort": "Trop court!",
        "tooLong": "Trop long!",
        "required": "Requis",
        "emailInvalid": "Entrez un email valide",
        "passwordInvalid": "Entrez un mot de passe valide",
        "phNumInvalid": "Numéro de téléphone invalide",
        "signupCap": "S'INSCRIRE",
        "signup": "S'inscrire",
        "verifyText": "Vérifiez que c'est vous",
        "verifyText2": "Nous avons envoyé un code de vérification à votre adresse email",
        "verificationCode": "Code de vérification",
        "didNotReceiveEmail": "Vous n'avez pas reçu l'email",
        "clickToResend": "Cliquez pour renvoyer",
        "verifyAndSignIn": "Vérifiez et connectez-vous",
        "otpSentSuccessfully": "OTP envoyé avec succès",
        "somethingWentWrong": "Une erreur s'est produite, veuillez réessayer",
        "otpError": "Veuillez entrer un numéro valide",
        "otpError2": "Veuillez entrer uniquement des chiffres",
        "otpError3": "OTP incorrect, veuillez réessayer",
        "otpError4": "OTP expiré, cliquez sur Renvoyer pour réessayer",
        "otpError5": "Veuillez réessayer",
        "forgetPassword": "Mot de passe oublié",
        "submitError": "L'utilisateur n'existe pas!",
        "userExistError": "L'utilisateur existe déjà",
        "checkYourEmail": "Vérifiez votre email",
        "passwordResetLinkSent": "Nous avons envoyé un lien de réinitialisation du mot de passe à",
        "noWorriesResetInstructions": "Pas de soucis, nous vous enverrons des instructions pour réinitialiser.",
        "enterYourEmail": "Entrez votre email",
        "resetMyPassword": "RÉINITIALISER MON MOT DE PASSE",
        "setANewPassword": "Définir un nouveau mot de passe",
        "newPassword": "Nouveau mot de passe",
        "confirmPassword": "Confirmer le mot de passe",
        "confirmPasswordError": "Les mots de passe doivent correspondre",
        "backToLogin": "Retour à la connexion",
        "dontHaveAccount": "Vous n'avez pas de compte?",
        "alreadyHaveAccount": "Vous avez déjà un compte?",
        "memberDiscount1": "Vous obtiendrez",
        "memberDiscount2": "une réduction membre à vie.",
        "discount1": "Gérez toutes vos réservations depuis un seul endroit.",
        "discount2": "Inscrivez-vous pour des recommandations personnalisées et des expériences sur mesure!!",
        "discount3": "Rejoignez notre communauté d'invités heureux et commencez à planifier votre séjour.",
        "leftGridText1": "Gérez toutes vos réservations depuis un seul endroit",
        "leftGridText2": "Inscrivez-vous pour des recommandations personnalisées et des expériences sur mesure!!",
        "leftGridText3": "Rejoignez notre communauté d'invités heureux et commencez à planifier votre séjour",
        "gridDiscountText1": "réduction membre sur",
        "gridDiscountText2": "des propriétés sélectionnées à vie",
        "gridDiscountText3": "Connectez-vous pour une expérience sur mesure à vie",
        "gridDiscountText4": "Obtenez des avantages exclusifs pour les membres",
        "findBookingError": "Votre email et identifiant de réservation ne correspondent pas",
        "verifyAndConfirmBooking": "Vérifiez et confirmez la réservation",
        "confirmEmailText": "Nous avons envoyé un code de vérification à votre adresse e-mail",
        "enterCodeText": "Veuillez entrer le code de vérification envoyé à vous",
        "didNotRecieveEmail": "Vous n'avez pas reçu l'email ?",
        "changeEmailText": "Cliquez ici pour changer l'adresse e-mail",
        "verifyAndConfirm": "Vérifier et Confirmer",
        "verified": "VÉRIFIÉ",
        "bookingExpired": "Réservation expirée",
        "bookingExpiredText": "Nous sommes désolés, l'identifiant de réservation que vous utilisez est expiré",
        "backToFindingBooking": "Retour à la recherche de réservation",
        "findMyBooking": "Trouver mes réservations",
        "bookingEmail": "Email de réservation",
        "reservationId": "Identifiant de réservation",
        "findBooking": "TROUVER UNE RÉSERVATION",
        "abandonmentCartDoneText1": "Merci !",
        "abandonmentCartDoneText2": "Les détails de votre panier ont été envoyés à votre e-mail. Continuez votre réservation à tout moment.",
        "Recommended Currencies": "Devises recommandées",
        "All currencies": "Toutes les devises",
        "thisIsRequiredField": "Ce champ est requis"
    },
    "widget": {
        "destination": "Destination",
        "enterDestination": "Entrez la destination",
        "guests": "Invités",
        "selectedRate": "Tarif sélectionné",
        "search": "Rechercher",
        "ages": "Âges",
        "orAbove": "Ou plus",
        "child": "Enfant",
        "children": "Enfants",
        "addAnotherRoom": "Ajouter une autre chambre",
        "removeRoom": "Supprimer la chambre",
        "property": "Propriété",
        "city": "Ville",
        "country": "Pays",
        "bookNow": "Réserver maintenant",
        "destinationIsRequired": "La destination est requise",
        "checkInIsRequired": "La date d'enregistrement est requise",
        "checkOutIsRequired": "La date de départ est requise"
    },
    "promotion": {
        "headingText": "Informations importantes pour",
        "regularRate": "Tarif Régulier",
        "seniorCitizen": "Tarif Senior",
        "agencyRate": "Tarif Agence",
        "caaRate": "Tarif CAA/AAA",
        "corporateRate": "Tarif Entreprise",
        "governmentRate": "Tarif Gouvernemental/Militaire",
        "promotionalSpecialRate": "Tarif Promotionnel/Spécial",
        "regularRateDescription": "Les tarifs réguliers sont les tarifs les plus abordables actuellement proposés pour la réservation d'une chambre d'hôtel.",
        "seniorCitizenRateDescription": "Il s'agit d'un tarif hôtelier réduit largement disponible pour les adultes plus âgés, généralement âgés de 60 ans ou plus.",
        "agencyRateDescription": "Il s'agit d'un tarif hôtelier réduit qui peut être accessible uniquement via certains agents de voyage favorisant le partenariat et la collaboration.",
        "caaRateDescription": "Il s'agit d'un tarif spécial proposé aux membres de l'AAA ou du CAA pour des économies exclusives.",
        "corporateRateDescription": "Il s'agit d'un tarif hôtelier réduit destiné aux voyageurs d'affaires, généralement négocié entre l'hôtel et l'entreprise.",
        "governmentRateDescription": "Ce plan tarifaire offre des réductions spécifiques pour le personnel gouvernemental/militaire pour la réservation d'une chambre d'hôtel.",
        "contentSeniorCitizen": "Une preuve de date de naissance est obligatoire lors de l'enregistrement. L'absence de présentation d'une pièce d'identité valide entraînera l'application des tarifs en vigueur.",
        "contentAgencyRate": "Assurez-vous d'avoir votre confirmation de réservation et votre pièce d'identité à votre arrivée. L'absence de ces documents entraînera l'application des tarifs standards.",
        "contentPromotionalSpecialRate": "Il s'agit d'un tarif hôtelier réduit offert pour une durée limitée, souvent lié à des événements spécifiques, des saisons ou des campagnes marketing.",
        "contentCaaRate": "Veuillez vous assurer d'avoir votre carte de membre CAA ou une pièce d'identité appropriée lors de l'enregistrement. L'absence de preuve d'identité entraînera l'application des tarifs en vigueur.",
        "contentCorporateRate": "Une preuve d'identité d'entreprise ou un autre document officiel est requis lors de l'enregistrement. L'absence de justificatif entraînera l'application des tarifs en vigueur.",
        "contentGovernmentRate": "Une pièce d'identité gouvernementale ou militaire valide doit être fournie lors de l'enregistrement. L'absence de cette pièce entraînera l'application des tarifs standards.",
        "CORP": "CORP",
        "AGEN": "AGEN",
        "PROM": "PROM",
        "apply": "APPLIQUER",
        "Offer Code": "Code Offre",
        "Agency Code": "Code Agence",
        "Corporate Code": "Code Entreprise",
        "All-Access Rate": "Tarif Tout Accès"             
    }
}