import {useEffect, useState} from 'react'
import 'react-dates/initialize';
import { DateRangePicker } from "react-dates";
import './RangeDateControl.css';
import moment from "moment";
import { CalendarToday } from '@mui/icons-material'
import { Box, Popper } from '@mui/material';
import Paper from '@mui/material/Paper';
import { createGlobalStyle } from "styled-components";
import { useTranslation } from '../../utility/function/useTranslations';

const RangeDateControl = (props: any) => {
    const { selectedDate, handleDateChange, touched, errors, isMobile= false, fontColor, booknowbuttoncolor, booknowbuttonfontcolor, booknowbuttonhovercolor, buttonHoverFontColor, setEventType, eventType, barData, setStartDate, navStartDate, demandData, barCurrency, isDemandCalendar, destinationType={type: "0"}, propertyID, currencyMappingData, isMultiCurrencyFFEnable, preferredCurrency} = props;
    const [startDate, endDate] = selectedDate;
    const [focusedInput, setFocusedInput] = useState<any>(isMobile?null:"startDate");
    const [open, setOpen] = useState(true);
    const [boxHidden, setBoxHidden] = useState(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [rateLegend, setRateLegend] = useState<any []>([]);
    const { t } = useTranslation();
    useEffect(() => {
      // Call dropDownPosition() when the component is initially rendered
      dropDownPosition();
      const handleScroll = () => dropDownPosition();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      if(isMobile) {
        setOpen(false)
      }
      if(!isMobile && document.getElementsByClassName("DayPicker_transitionContainer")){
        let ele = document.getElementsByClassName("DayPicker_transitionContainer")[0];
        setAnchorEl(ele);
      }
      return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleScroll);
      };
    }, []);

    useEffect(() => {
      if(barData.length>0){
        const legends: any[] = [];
        let num=0;
        while(legends.length<=2 && num < barData.length){
          let barDayData = barData[num];
          let price = (barCurrency !== preferredCurrency?.code && currencyMappingData?.length > 0) ? convertPriceToPreferredCurrencyNonMemo(barDayData?.rate, barCurrency, false, true): barDayData?.rate ? barDayData?.rate: 0;
          if(price >= 10000 && !legends.includes("k")){
            legends.push("k");
          }
          if(price >= 1000000 && !legends.includes("m")){
            legends.push("m");
          }
          num++;
        } 
        if(JSON.stringify(rateLegend) !== JSON.stringify(legends) ){
          setRateLegend(legends);
        }
      }

    },[barData, propertyID, preferredCurrency])

    function getLastDateOfNextMonth(dateStr: string) {
      let date = new Date(dateStr);
      let year = date.getFullYear();
      let month = date.getMonth();
      let nextMonth = month + 2;
      if (nextMonth > 11) {
          year += 1;
          nextMonth -= 12;
      }
      let firstDayOfNextMonth: any = new Date(year, nextMonth, 1);
      let lastDayOfNextMonth: any = new Date(firstDayOfNextMonth - 1);
      let formattedDate = lastDayOfNextMonth.toISOString().split('T')[0];
      return formattedDate;
    }

    function increaseDateByTwoMonths(dateString: string) {
      var parts = dateString.split('-');
      var year = parseInt(parts[0]);
      var month = parseInt(parts[1]);
      var day = parseInt(parts[2]);
      month += 2;
      if (month > 12) {
        year += Math.floor((month - 1) / 12);
        month = (month - 1) % 12 + 1;
      }
      var isLeapYear = (year % 4 == 0 && year % 100 != 0) || (year % 400 == 0);
      var daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (day > daysInMonth[month - 1]) {
        day = daysInMonth[month - 1];
      }
      var newDateString = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
      return newDateString;
    }

    const handleNavigationButtonClick = () => {
      if(destinationType?.type=="2"){
        if(navStartDate.length==1){
          if(navStartDate[0].date!=null){
            if(navStartDate[0].fetch){
              let _date = increaseDateByTwoMonths(navStartDate[0].date);
              if(!isOneYearAhead(_date)){
                setStartDate([...navStartDate, {date: _date, fetch: true}]);
              }
            }else{
              let _date = increaseDateByTwoMonths(navStartDate[0].date);
              if(!isOneYearAhead(_date)){
                setStartDate([{date: _date, fetch: true}]);
              }
            }
          }else{
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');  
            let _date = selectedDate.length>0 && Date.parse(selectedDate[1]) >= today.setMonth(today.getMonth() + 2)? getLastDateOfNextMonth(selectedDate[1]):increaseDateByTwoMonths(`${year}-${month}-${day}`);
            if(!isOneYearAhead(_date)){
              setStartDate([{date: _date, fetch: true}]);
            }
          }
        }else{
          let _date = increaseDateByTwoMonths(navStartDate[navStartDate.length-1].date);
          if(!isOneYearAhead(_date)){
            setStartDate([...navStartDate ,{date: _date, fetch: true}]);
          }
        }
      }      
    };

    const compareDates = (d1: any, d2: any) => {
        var date1 = new Date(d1._d);
        var date2 = d2? new Date(d2): new Date();
        var year1 = date1.getFullYear();
        var month1 = date1.getMonth();
        var day1 = date1.getDate();
        var year2 = date2.getFullYear();
        var month2 = date2.getMonth();
        var day2 = date2.getDate();
        if (year1 < year2) {
            return -1;
        } else if (year1 > year2) {
            return 1;
        }

        if (month1 < month2) {
            return -1;
        } else if (month1 > month2) {
            return 1;
        }

        if (day1 < day2) {
            return -1;
        } else if (day1 > day2) {
            return 1;
        }

        // If all components are equal, dates are equal
        return 0;
    }

    const isOneYearAhead = (day: any) => {
      const givenDate = day?._d? new Date(day._d): new Date(day);
      givenDate.setHours(0, 0, 0, 0);
      const currentDate = new Date();
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);
      oneYearFromNow.setHours(0,0,0,0);
      return givenDate >= oneYearFromNow
    }

    const isDayBlocked = (day: any) => {
      if(isOneYearAhead(day)){
        return true;
      }
      if(focusedInput== "endDate"){
        return false;
      }
      if(destinationType?.type=="2"){
        let barDayData = barData.length>0? barData[barData.findIndex((ele: any)=> compareDates(day, ele.date)==0)]: null;
        if(barDayData==null){
          return false;
        }
        if(barDayData?.inventory<=0 || barDayData?.rate==0){
          return true;
        } 
      }
      return false;
    };

    function formatNumber(num: number) {
      num = Math.ceil(num);
      if (num >= 1000000000) {
        // If the number is 1 billion or more
        return (num / 1000000000).toFixed(1) + 'B';
      } else if (num >= 1000000) {
        // If the number is 1 million or more
        return (num / 1000000).toFixed(1) + 'M';
      } else if (num >= 10000) {
        // If the number is 10 thousand or more
        return (num / 1000).toFixed(1) + 'K';
      } else {
        // If the number is less than 10 thousand
        return num;
      }
    }

    const convertPriceToPreferredCurrencyNonMemo = (price: any, currencyType: any, isFixedToDecimal: boolean = false, convertToPreferredCurrency = true) => {
      if(isMultiCurrencyFFEnable && convertToPreferredCurrency) {
          const preferredCurrencyConfig = currencyMappingData?.find((x: any) => x.targetCurrencyCode === preferredCurrency?.code);

          // When we have the target currency and currency is same
          if(preferredCurrencyConfig?.targetCurrencyCode === currencyType) {
              if(isFixedToDecimal) {
                  return parseFloat(price?.toString()).toFixed(2);
              }
              return price;
          }
          else {
              // This if block execute when we no currency selected in dropdown
              if(!preferredCurrencyConfig?.targetCurrencyCode) {
                  return price;
              }
              else {
                  const ConversionRatioForPropertyCurrency = currencyMappingData?.find((x: { targetCurrencyCode: any; }) => x.targetCurrencyCode === currencyType );
                  const propertyCurrencyConversion = price / ConversionRatioForPropertyCurrency?.conversionRate;
                  //console.log("🚀 ~ convertPriceToPreferredCurrency ~ propertyCurrencyConversion:", propertyCurrencyConversion)
                  const preferredCurrencyConversion = propertyCurrencyConversion * preferredCurrencyConfig?.conversionRate;
                  //console.log("🚀 ~ convertPriceToPreferredCurrency ~ preferredCurrencyConversion:", preferredCurrencyConversion)
                  if(isFixedToDecimal) {
                      return parseFloat(preferredCurrencyConversion.toString()).toFixed(2);
                  }
                  return preferredCurrencyConversion;
              }
          }
      }
      else {
          if(isFixedToDecimal) {
              return parseFloat(price?.toString()).toFixed(2);
          }
          return price;
      }
    };

    const renderDayContents = (day: any) => {
      if(compareDates(day,null)==-1){ //for dates less than the todays date.
        return (
          <div style={{display:"flex", flexDirection:"column"}}>
            {day.format('D')}
            <span style={{fontSize:"8px", opacity:"0.5", marginTop:"1px"}}>--</span>
          </div>
        )
      }
      if(!isDemandCalendar || isOneYearAhead(day)){
        return (
          <div style={{display:"flex", flexDirection:"column"}}>
            {day.format('D')}
            <span style={{fontSize:"8px", opacity:"0.5", marginTop:"1px"}}>{}</span>
          </div>
        );
      }
      if(destinationType?.type=='1')  { //for search where the destination is CITY, so no data for bar is coming
        let demandDayData = demandData.length>0? demandData[demandData.findIndex((ele: any)=> compareDates(day, ele.checkinDate)==0)]: {};
        return (
          <div style={{display:"flex", flexDirection:"column", color: demandDayData?.demandIndex>90?"#EB2727":""}}>
            {day.format('D')}
            <span style={{fontSize:"8px", opacity:"0.5", marginTop:"1px"}}>{}</span>
          </div>
        );
      } else if(destinationType?.type=='2')  { //for search where the destination is PROPERTY, so data for bar and demand is coming
        let barDayData = barData.length>0? barData[barData.findIndex((ele: any)=> compareDates(day, ele.date)==0)]: null;
        if(barDayData!=null && (barDayData?.inventory<=0 || barDayData?.rate==0) && focusedInput!="endDate"){
          return (
            <div style={{display:"flex", flexDirection:"column", backgroundColor: "lightgrey", opacity: 0.4, padding: "2px"}}>
              {day.format('D')}
              <span style={{fontSize:"8px", opacity:"0.5", marginTop:"1px"}}>--</span>
            </div>
          );
        }
        let demandDayData = demandData.length>0? demandData[demandData.findIndex((ele: any)=> compareDates(day, ele.checkinDate)==0)]: {};
        let price = barDayData!=null && barDayData?.rate ? (barCurrency !== preferredCurrency?.code && currencyMappingData?.length > 0) ? formatNumber(convertPriceToPreferredCurrencyNonMemo(barDayData?.rate, barCurrency, false, true)): formatNumber(barDayData?.rate): "";
        return (
          <div style={{display:"flex", flexDirection:"column", color: demandDayData?.demandIndex>90?"#EB2727":""}}>
            {day.format('D')}
            <span style={{fontSize:"8px", opacity:"0.5", marginTop:"1px"}}>{price=="NaN"? "--": price}</span>
          </div>
        );
      } else { //for search where the destination is COUNTRY, so no data for bar and demand is coming
        return (
          <div style={{display:"flex", flexDirection:"column"}}>
            {day.format('D')}
            <span style={{fontSize:"8px", opacity:"0.5", marginTop:"1px"}}>{}</span>
          </div>
        );
      }
    };

    useEffect(() => {
      if(eventType === '' && !isMobile) {
        setOpen(false);
        setFocusedInput("startDate");
      }
      if(!isMobile && eventType=="onMouseClick" && open){
        let ele = document.getElementsByClassName("DayPicker_transitionContainer")[0];
        setAnchorEl(ele); 
      }
    }, [eventType]);

    const handleFocusChange = (_focusedInput: any) => {
      if(isMobile){
        setFocusedInput(_focusedInput);
      }else{
        if(_focusedInput==null){
          if(open){
            setEventType("");
          }
          setOpen(false);
          setFocusedInput(null);
          setTimeout(()=>{
            setFocusedInput("startDate");
          },1)
        }else{
          setFocusedInput(_focusedInput);
        }
      }
    };

    useEffect(()=>{
      if(focusedInput!=null){
        if(isMobile && document.getElementsByClassName("DayPicker_transitionContainer")){
          let ele = document.getElementsByClassName("DayPicker_transitionContainer")[0];
          setAnchorEl(ele);
        }
      }else{
        setAnchorEl(null);
      }
    },[focusedInput])

    const getDate = (date: any) =>  {
      const formattedDate = dateObject(date);
      const isoDate = dateObject(`${formattedDate}T00:00:00.000Z`);
      let momentDate = moment(new Date(isoDate));
      return momentDate;
    };

    const dateObject = (date: any) =>  {
      let dateObject = date?.split('-').map((item: string | any[]) => {
        if(item.length < 2) {
          return `0${item}`
        }return item
      })
      return dateObject.join('-');
    }

    const dropDownPosition = () => {
      const boxElement = document.getElementById('IBE_Calendar_Pos');
      if(!boxElement) return;
        const boxPosition = boxElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if(boxPosition.bottom < 0 || (boxPosition.top + boxPosition.bottom) > windowHeight){
          setBoxHidden(true);
        } else{
          setBoxHidden(false);
        }
    }

    const GlobalStyles = createGlobalStyle`
        .widgetDateRange .DateRangePickerInput_arrow svg, .widgetDateRange .startDate-icon-widget svg{
            color: ${fontColor} !important;
        }
        .widgetDateRange .DateInput.DateInput_1::before  {
            color: ${fontColor} !important;
        }
        #checkInDateWidget, #checkOutDateWidget {
            color: ${fontColor} !important;
        }
        #checkInDateWidget:focus-visible, #checkOutDateWidget:focus-visible {
          outline: none
        }
        .CalendarDay__selected,
        .CalendarDay__selected:active {
          background: ${booknowbuttoncolor} !important;
          border: 1px double ${booknowbuttoncolor} !important;
          color: ${booknowbuttonfontcolor} !important;
        }
        .CalendarDay__selected:hover {
          background:  ${isMobile? booknowbuttoncolor:booknowbuttonhovercolor} !important;
          border: 1px double ${isMobile? booknowbuttoncolor:booknowbuttonhovercolor} !important;
          color: ${isMobile? booknowbuttonfontcolor:buttonHoverFontColor} !important;
        }
        .CalendarDay__selected_span, .CalendarDay__selected_span:active {
          background: ${booknowbuttoncolor} !important;
          border: 1px double ${booknowbuttoncolor};
          opacity: 50%;
          color: ${booknowbuttonfontcolor} !important;
        }
        .CalendarDay__selected_span:hover {
          background: ${booknowbuttonhovercolor} !important;
          border: 1px double ${booknowbuttonhovercolor} !important;
          opacity: 100%;
        }
        .CalendarDay__default:hover {
          background: ${isMobile? booknowbuttoncolor:booknowbuttonhovercolor};
          color: ${isMobile? booknowbuttonfontcolor:buttonHoverFontColor} !important;
        }
        .CalendarMonth_caption {
          color: black !important;
        }
        .CalendarDay__default {
          color: black;
        }
        .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
          opacity : 50%;
          color: black !important;
        }
        .CalendarDay__blocked_out_of_range:hover {
          background: none;
        }
        .DayPicker_weekHeader_ul {
          color: black !important;
        }
        .widgetDateRange .DateInput.DateInput_1:first-of-type::before {
          content: "${t("common","checkin")}" !important;
        }
        .widgetDateRange .DateInput.DateInput_1:not(:first-of-type)::before {
          content: "${t("common","checkout")}" !important;
        }
    `;

    return (
      <Box component="div" id="Calendar-container" onClick={()=>{if(!open)setOpen(true)}}>
        <GlobalStyles />
        <Box component="div" className={isMobile? "widgetDateRange": open?"openWidget widgetDateRange":"closeWidget widgetDateRange"} sx={{height: '100%' }}>
          <Box component="div" className="calenderIcon-widget startDate-icon-widget" id="IBE_Calendar_Pos">
            <CalendarToday sx={{ fontSize:'1.1rem !important', color: fontColor? `${fontColor} important` : "#fff"}}></CalendarToday>
          </Box>
          <DateRangePicker
            startDateId="checkInDateWidget"
            endDateId="checkOutDateWidget"
            startDate={getDate(startDate)}
            endDate={getDate(endDate)}
            onDatesChange={(value) => handleDateChange(value)}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            readOnly={true}
            // customInputIcon={<CalendarToday/>}
            customArrowIcon={<CalendarToday/>}
            hideKeyboardShortcutsPanel={true}
            displayFormat="DD MMM, YY"
            startDateAriaLabel="Check-InASSD"
            startDatePlaceholderText="check-InASD"
            endDatePlaceholderText="check-OutASDASD"
            numberOfMonths={isMobile? 1: 2}
            anchorDirection={'left'}
            openDirection='up'
            renderDayContents={renderDayContents}
            onNextMonthClick={()=>handleNavigationButtonClick()}
            isDayBlocked={isDayBlocked}
          />  
          {open && anchorEl!=null && isDemandCalendar && (barData.length>0 || demandData.length>0) &&  
            <Popper id='simple-popper' open={open} anchorEl={anchorEl} placement='bottom-end' sx={{zIndex:"1300", inset:isMobile?"-2px 0px auto auto !important":"-4px -1px auto auto !important"}}>
              {isMobile?
                <Paper sx={{
                  width: "318.5px",
                  borderRadius: "0px 0px 5px 5px",
                  boxShadow: "unset",
                  display: (open && anchorEl!=null)? "block !important" : "none !important",
                  border: "1px solid hsl(214.3 31.8% 91.4%)",
                  borderTop:"none"
                }}>
                  <div style={{ display:"flex", flexDirection:"row-reverse", padding: "10px 14px 10px 0px", backgroundColor: "#FAFAFA"}}>
                    {barData.length>0 && barCurrency && <span style={{fontSize:"8px", color: "#8A8C9A", marginLeft:"8px"}}>All rates are in {preferredCurrency?.code ? preferredCurrency?.code : barCurrency}</span>}
                    {rateLegend.includes("k") && destinationType.type=="2" && <span style={{fontSize:"8px", color: "#8A8C9A", marginLeft:"8px"}}>K = 1000</span>}
                    {rateLegend.includes("m") && destinationType.type=="2" && <span style={{fontSize:"8px", color: "#8A8C9A", marginLeft:"8px"}}>M = 1000000</span>}
                    {demandData.length > 0 &&
                      <div style={{display:"flex", marginLeft:"8px"}}>
                        <span style={{fontSize:"8px", color: "#EB2727"}}>High Demand</span>
                        <span style={{fontSize:"8px", color: "#8A8C9A", marginLeft:"2px"}}>Dates</span>
                      </div>
                    }
                    {barData.length>0 &&
                      <div style={{display:"flex"}}>
                        <div style={{width:"8px", height:"8px", backgroundColor:"#EBEBEB", borderRadius:"2px", marginTop:"1px"}}/>
                        <span style={{fontSize:"8px", color: "#8A8C9A", marginLeft:"2px"}}>Sold out</span>
                      </div>
                    }
                  </div>
                </Paper>
              :
                <Paper sx={{
                  width: "619px",
                  borderRadius: "0px 0px 5px 5px",
                  boxShadow: "unset",
                  display: (open && anchorEl!=null)? "block !important" : "none !important",
                  border: "1px solid hsl(214.3 31.8% 91.4%)",
                  borderTop:"none"
                }}>
                  <div style={{ display:"flex", flexDirection:"row-reverse", padding: "10px 20px 10px 0px", backgroundColor: "#FAFAFA"}}>
                    {barData.length>0 && barCurrency && <span style={{fontSize:"11px", color: "#8A8C9A", marginLeft:"14px"}}>All rates are in {barCurrency}</span>}
                    {rateLegend.includes("k") && destinationType.type=="2" && <span style={{fontSize:"11px", color: "#8A8C9A", marginLeft:"14px"}}>K = 1000</span>}
                    {rateLegend.includes("m") && destinationType.type=="2" && <span style={{fontSize:"11px", color: "#8A8C9A", marginLeft:"14px"}}>M = 1000000</span>}
                    {demandData.length > 0 &&
                      <div style={{display:"flex", marginLeft:"14px"}}>
                        <span style={{fontSize:"11px", color: "#EB2727"}}>High Demand</span>
                        <span style={{fontSize:"11px", color: "#8A8C9A", marginLeft:"3px"}}>Dates</span>
                      </div>
                    }
                    {barData.length>0 &&
                      <div style={{display:"flex"}}>
                        <div style={{width:"12px", height:"12px", backgroundColor:"#EBEBEB", borderRadius:"4px", marginTop:"1px"}}/>
                        <span style={{fontSize:"11px", color: "#8A8C9A", marginLeft:"4px"}}>Sold out</span>
                      </div>
                    }
                  </div>
                </Paper>
              }
            </Popper>
          }
        </Box>
      </Box>
    );
}
export default RangeDateControl