import { Autocomplete, Box, CircularProgress, List, ListItem, ListItemText, Skeleton, TextField, ThemeProvider, Typography, createTheme, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HotelIcon from '@mui/icons-material/Hotel';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { getIBE_API } from '../getAPIUrl';
import { createGlobalStyle } from 'styled-components'
import { useTranslation } from '../utility/function/useTranslations';

enum locationType {
  country,
  city,
  property,
  pincode
}
interface ILocation {
  location?: string;
  id?: string;
  type: locationType;
}

const removeDuplicateFromArray = (arrayItems: any[]) => {
  const jsonObject = arrayItems.map(x => JSON.stringify(x));
  const uniqueSet = new Set(jsonObject);
  return Array.from(uniqueSet).map(y => JSON.parse(y));
}

function MobileSearchControl(props: any) {
  const theme: any = useTheme();
  const { brandID, chainID, touched, errors, setFieldValue, setMaxChildAge, dest, fontColor, booknowbuttoncolor, booknowbuttonfontcolor, buttonHoverFontColor, booknowbuttonhovercolor, values, setPageLoaded, setDestinationOptionList } = props
  const [response, setResponse] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    const fetchLocationData = async () => {
      const IBE_lOCATION_API = getIBE_API();
      fetch(`${IBE_lOCATION_API}/Search/getLocationInfo?brandID=${brandID}&chainID=${chainID}&languageCode=${window.location.href.split("/").filter(item => item !== '')[2]? window.location.href.split("/").filter(item => item !== '')[2]: 'en'}`)
        .then((response: any) => response.json())
        .then((data) => {
          if (data?.status) {
            let locations: ILocation[] = [];
            setMaxChildAge(data?.body[0]?.childAgeLimit || 17)
            const response = data.body;
            setResponse(response)
            setDestinationOptionList(response);
            // Extract countries
            Array.from(new Set(response.map((item: any) => item.countryId))).map(countryId => {
              const country = response.find((item: any) => item.countryId === countryId);
              locations.push({ id: country.countryId, location: country.country, type: locationType.country });
              // Extract cities
              Array.from(new Set(response.map((item: any) => item.cityId))).map(cityId => {
                const city = response.find((item: any) => item.cityId === cityId && item.countryId === countryId);
                if (city) {
                  locations.push({ id: city?.cityId, location: city.city, type: locationType.city });
                  // Extract property names
                  Array.from(new Set(response.map((item: any) => item.propertyId))).map(propertyId => {
                    const properties = response.filter((item:any) => item.cityId === cityId &&  item.countryId === countryId);
                    if(properties.length>0){
                      properties.map((p: any) => {
                        locations.push( { id: p?.propertyId, location: p?.name, type: locationType.property });
                      })
                    }
                  })
                }
              });
              //output.push(...cities);
            });
            locations = removeDuplicateFromArray(locations);
            setOptions(locations);
            if(response.length === 1) {
              const selectedProperty = locations.find(x => x.id === response[0]?.propertyId);
              onChangeAutoComplete('', selectedProperty, setFieldValue, touched)
            }
            // setLocations(locations)
            setLoading(false);
          }
        }
        );
    }
    fetchLocationData();
    // console.log({ options })
  }, []);

  const styles = {
    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      position: 'absolute',
      top: 'calc(60% - 10px)'
    },
    '& .MuiFormLabel-filled': {
      color: '#9ca5ac !important'
    },
    '& label.Mui-focused': {
      color: '#9ca5ac'
    },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: '0px !important',
      '& fieldset': {
        borderColor: 'transparent',
        border: 'none',
        color: '#9ca5ac'
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
        border: 'none',
        color: '#9ca5ac'
      },
      '&.Mui-focused fieldset': {
        //borderColor: 'white',
        border: '0px',
        color: '#9ca5ac'
      },
      'input': {
        '&::placeholder': {
          fontSize: '12px !important',
          fontWeight: 300,
          color: fontColor? `${fontColor} !important`: 'white solid !important',
          opacity: 1
        }
      }
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-clearIndicator': {
      color: `${fontColor} !important `,
      backgroundColor: `inherit !important`,
      padding : '0px !important'
    },
    '&.MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator': {
      color: `${fontColor} !important `
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
      color: `${fontColor} !important `
    },
  }

  const onChangeAutoComplete = (event: any, value: any, setFieldValue: any, touched: any) => {
    touched.destination = {
      location: true,
      type: true
    }
    setPageLoaded(true);
    setFieldValue('destination', value, true);
  }

  const filterOptions = (options: any, { inputValue }: any) => {
    if (inputValue === "") {
      // setOpenSearch(false)
      return options?.filter((option: any) => {
        return option?.type !== locationType.pincode
      }
      )
    }
    return options?.filter((option: any) =>
      option?.location?.toLowerCase()?.includes(inputValue.toLowerCase())
    );
  };

  const renderOption = (props: any, option: any, { selected }: any) => (
    <li {...props} style={{ borderBottom: '1px solid #f6f7f7', paddingRight: '0px' }}>
      {option?.type === locationType.property ?

        <Box display={'flex'}>
          <HotelIcon sx={{ color: 'grey', fontSize: '18px' }} />
          <Box display={'flex'} flexDirection={'column'} pl={2} width={'auto'}>
            <Typography sx={{ color: 'black', fontWeight: '450 !important', fontSize: '16px', lineHeight: '1', }}>{getPropertyCountry(option?.location)}</Typography>
            <Typography sx={{ color: '#b3b0b0', fontSize: '13px' }}>{t("widget","property")}</Typography>
          </Box>
        </Box>
        :
        option?.type === locationType.city ?
          <Box display={'flex'}>
            <LocationCityIcon sx={{ color: 'grey', fontSize: '18px' }} />
            <Box display={'flex'} flexDirection={'column'} pl={2} width={'auto'}>
              <Typography sx={{ color: 'black', fontWeight: '500!important', fontSize: '16px', lineHeight: '1' }}>{option?.location}</Typography>
              <Typography sx={{ color: '#b3b0b0', fontSize: '13px' }}>{t("widget","city")}</Typography>
            </Box>
          </Box>
          :
          <Box display={'flex'}>
            <LocationOnIcon sx={{ color: 'grey', fontSize: '20px' }} />
            <Box display={'flex'} flexDirection={'column'} pl={2}>
              <Typography sx={{ color: 'black', fontWeight: '500!important', fontSize: '16px', lineHeight: '1' }}>{option?.location}</Typography>
              <Typography sx={{ color: '#b3b0b0', fontSize: '13px' }}>{t("widget","country")}</Typography>
            </Box>
          </Box>
      }
    </li>
  );

  const themedata = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            // Increase the width of the options container
            width: "calc(100% + 60px)", // Specify your desired width here
            left:'30px',
            marginLeft:'-14px'
          },
          input:{
            color: fontColor? `${fontColor} !important` : "#fff !important",
          },
        },
      },
    },
  });

  function getPropertyCountry(location: any): React.ReactNode {
    const country = response.find((x: any) => x.name.toLocaleLowerCase() === location.toLocaleLowerCase())?.country;
    return <Typography>{location}, <b>{country}</b>
    </Typography>
  }

  const renderInput = (params: any) => {
    //console.log(' params.inputProps.value', params.inputProps.value, dest?.location)
    return (
      params.inputProps.value || !dest?.location ?
        <TextField
          {...params}
          placeholder={t("widget","enterDestination")}
          error={Boolean(touched.destination && errors.destination)}
          sx={{ color: `${theme?.searchFontColor} !important ` }}
          InputProps={{
            ...params.InputProps,
            style: { color: `${theme?.searchFontColor} !important ` },
            endAdornment: params.inputProps.value && (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
        :
        <TextField
          sx={{ ...styles, color: `${theme?.searchFontColor} !important `, border: 'none' }}
          value={dest?.location} />
    )
  };

  // const renderInput = (params: any) => (
  //   <TextField
  //     {...params}
  //     placeholder='ENTER DESTINATION'
  //     error={Boolean(touched.destination && errors.destination)}
  //     InputProps={{
  //       ...params.InputProps,
  //       endAdornment: params.inputProps.value && (
  //         <>
  //           {loading && <CircularProgress size={20} />}
  //           {params.InputProps.endAdornment}
  //         </>
  //       ),
  //     }}
  //   />
  // );

  const GlobalStyles = createGlobalStyle`
    .MuiAutocomplete-listbox {
      cursor: pointer
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] {
      background-color: ${booknowbuttoncolor} !important;
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] p, .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] svg {
      color: ${booknowbuttonfontcolor} !important;
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused p, .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused svg {
      color: ${buttonHoverFontColor} !important;
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
      background-color: ${booknowbuttonhovercolor} !important;
    }
  `

  return (
    <Box component="div" sx={{ width: "100%" }}>
      <GlobalStyles />
      <ThemeProvider theme={themedata}>
      <Autocomplete
        // componentsProps={{ popper: { style: { width: 'auto', left: '30px' } } }}
        sx={{ ...styles, border: 'none', '&:hover': { 'border': 'none' } }}
        value={values?.destination}
        blurOnSelect={true}
        isOptionEqualToValue={(option, value) => option.location === value?.location}
        defaultValue={dest|| null}
        noOptionsText={'No Location Found'}
        onChange={(event: any, value: any) => onChangeAutoComplete(event, value, setFieldValue, touched)}
        options={options}
        getOptionLabel={(option: any) => option.location}
        filterOptions={filterOptions}
        renderInput={renderInput}
        renderOption={renderOption}
      />
      </ThemeProvider>
    </Box>
  )
}

export default MobileSearchControl;