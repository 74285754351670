import React, {useState, useEffect} from 'react';
import { Box, Typography, useTheme, Divider, MenuItem, Select, FormControl, Button, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import styles from './addRoom.module.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PersonIcon from '@mui/icons-material/Person';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _ from 'lodash';
import { useTranslation } from '../utility/function/useTranslations';

interface RoomObjType {
    adultCount: number
    childCount: number
}

export default function MobileAddRoom(props: any){
    const {widgetValues, setFieldValue, maxChildAge, guestObj, openGuest, setOpenGuest, fontColor, booknowbuttoncolor, booknowbuttonhovercolor } = props
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const handleClickOpen = () => {
        setOpen(true);
        setOpenGuest(true);
        if(!openGuest) {
            if((parent as any)?.window.document.getElementById("37316DCF-9BB6-4B80-BE26-7651D87C5F6B_outerRGdiv")){
              (parent as any).window.document.getElementById("37316DCF-9BB6-4B80-BE26-7651D87C5F6B_outerRGdiv").style = "height: auto; position: absolute; top: 0px; left: 0px; width: 100vw;";
            }
            if((parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")){        
              (parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe").style.height = "100vh";
            }
        }
    };

    useEffect(() => {
        setOpen(openGuest)
    }, [openGuest])

    const handleClose = () => {
        setOpen(false);
        setOpenGuest(false);
        if((parent as any)?.window.document.getElementById("37316DCF-9BB6-4B80-BE26-7651D87C5F6B_outerRGdiv")){
            (parent as any).window.document.getElementById("37316DCF-9BB6-4B80-BE26-7651D87C5F6B_outerRGdiv").style = "height: auto; position: static;";
        }
        if((parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")){        
            (parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe").style.height = "600px"; 
        }
    };
    
    const styleChangeRoom = {
        travel: {
            backgroundColor: '#17202B',
            color: '#fff',
            padding: '20px 30px'
        },
        roomDetails: {
            padding: '15px 30px',
            minHeight: '100%',
            position: 'absolute',
            top: '60px',
            left: '0px',
            right: '0px',
            backgroundColor: '#17202B'
        },
        tableData: {
            backgroundColor: '#526781',
            color: '#fff',
            borderRadius: '6pt',
            width: '100%',
            marginTop: '30px'
        },
        heading: {
            fontSize: '12px',
            color: '#fff',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important'
        },
        removeBorder: {
            color: '#fff',
            borderBottom: 'none',
            fontSize: '14px'
        },
        paddLeft: {
            padding: '16px !important'
        },
        textChange: {
            fontSize: '10pt'
        },
        removeRoom: {
            border: '1px solid #707070',
            borderRadius: '6px',
            opacity: '0.39',
            color: '#2952DD',
            fontSize: '14px',
            backgroundColor: '#fff',
            width: '110px',
            height: '28px',
            display: 'block',
            textDecoration: 'none',
            paddingTop: '4px',
            float: 'right',
            textAlign: 'center'
        },
        addAnotherRoom: {
            marginTop: '30px',
            color: '#2952DD',
            fontSize: '18pt',
            clear: 'both'
        },
        done: {
            width: '100%',
            fontSize: '18px !important',
            letterSpacing: '0.36px !important',
            backgroundColor: '#2952DD !important',
            borderRadius: '6pt !important',
            color: '#fff !important',
            marginTop: '30px !important',
            padding: '20px 0px !important'
        }
    }

    const [roomObj, setRoomObj] = useState<[any]>(_.cloneDeep(guestObj));
    const [adultCount, setAdult] = useState<number>(2);
    const [childCount, setChild] = useState<number>(0);

    const revertValues = ()=> setRoomObj(_.cloneDeep(widgetValues.guest));

    const removeRoom = () => {
        const newRoomObj: [RoomObjType] = [...roomObj];
        if (newRoomObj.length > 1) {
            newRoomObj.pop()
        }
        setRoomObj(newRoomObj);

    }

    const changeCounter = (isIncrement: boolean, index: number, type: string) => {
        const newRoomObj: any = [...roomObj];
        if (isIncrement)
            newRoomObj[index][type] = newRoomObj[index][type] + 1
        else {
            newRoomObj[index][type] = newRoomObj[index][type] - 1
        }

        setRoomObj(newRoomObj);
    }

    const AddRoom = () => {
        const newRoomObj: [RoomObjType] = [...roomObj];
        newRoomObj.push({ adultCount: 2, childCount: 0 });
        setRoomObj(newRoomObj)
    }

    useEffect(() => {
       setAdult( roomObj.reduce((n, {adultCount}) => n + adultCount, 0));
       setChild( roomObj.reduce((n, {childCount}) => n + childCount, 0));
       //updateData()
    }, [roomObj,adultCount,childCount])
    
    const updateData = () => {
        const widgetDataCopy: any =  [];
        roomObj.map((data: any, index: number): any => {
                const widgetDataObj = {
                    roomCount: index+1,
                    adultCount: data?.adultCount,
                    childCount: data?.childCount
                }
                return widgetDataCopy.push(widgetDataObj);
            });

        setFieldValue('guest', widgetDataCopy, true);
        // handleCloseFilter();
    }

    const getTotal = (inputArray: any[], key: any) => inputArray.reduce(function (acc: any, obj: any) { return acc + obj[key]; }, 0);

    return(
            <Box component="div" className={ styles.addRoomGuest }>
                <p onClick={handleClickOpen} style={{ fontSize: '14px', fontWeight: 300, textTransform:'uppercase', display: 'inline-block', color: fontColor? fontColor: "#fff", marginBottom: '0px' }}>{roomObj.length} {t("common", roomObj.length > 1 ? "rooms": "room")}, {getTotal(widgetValues.guest, 'adultCount')} {t("common", getTotal(widgetValues.guest, 'adultCount') > 1 ? 'adults' : 'adult')}</p>
                <PersonIcon onClick={handleClickOpen} sx={{ color: fontColor? fontColor: "#fff", float: 'right', mr: '20px', mt: '5px', fontSize: '24px', opacity: '100%' }} />
                <Dialog fullScreen open={open}>
                    <DialogContent sx={{ p: '0px', mt: '-15px' }}>
                        <DialogContentText>
                            <Box component="div" sx={ styleChangeRoom.travel }>
                                <Box component="p" onClick={()=>{revertValues();handleClose();}}>
                                    <KeyboardBackspaceIcon sx={{ verticalAlign: 'bottom' }}></KeyboardBackspaceIcon>&nbsp;&nbsp;{t("widget","guests")}
                                </Box>
                            </Box>
                            <Box component="div" sx={ styleChangeRoom.roomDetails}>
                                <div> 
                                    <TableContainer>
                                        <Table sx={ styleChangeRoom.tableData }>
                                            {roomObj.map((data: any, index: number): any => {
                                                return (
                                                    <TableBody key={"room"+index}>
                                                        <TableRow>
                                                            <TableCell sx={[styleChangeRoom.removeBorder, styleChangeRoom.paddLeft]}>
                                                                <Typography variant='h6' sx={ styleChangeRoom.heading } style={{textTransform:"uppercase"}}>{t("common","room")} {index + 1}</Typography>
                                                            </TableCell>
                                                        </TableRow>                                            
                                                        <TableRow>
                                                            <TableCell sx={[ styleChangeRoom.removeBorder, styleChangeRoom.paddLeft ]} style={{textTransform:"uppercase"}}>{t("common","adults")}<br/>
                                                                <span style={ styleChangeRoom.textChange }>{t("widget","ages")} {maxChildAge+1} {t("widget","orAbove")}</span>
                                                            </TableCell>
                                                            <TableCell sx={styleChangeRoom.removeBorder}>
                                                                {data?.adultCount}
                                                            </TableCell>
                                                            <TableCell sx={ styleChangeRoom.removeBorder }>
                                                                {(data?.adultCount > 1) && <RemoveCircleIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} onClick={() => changeCounter(false, index, 'adultCount')} />}
                                                                {(data?.adultCount === 1) && <RemoveCircleOutlineIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB'  }} />}
                                                            </TableCell>                                                                                                                               
                                                            <TableCell sx={styleChangeRoom.removeBorder}>
                                                                {data?.adultCount < 25 && <AddCircleIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} onClick={() => changeCounter(true, index, 'adultCount')} />}
                                                                {(data?.adultCount > 24) && <AddCircleOutlineIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} />}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={[ styleChangeRoom.removeBorder, styleChangeRoom.paddLeft ]} style={{textTransform:"uppercase"}}>
                                                                {t("widget","children")}<br/>
                                                                <span>{t("widget","ages")} 0-{maxChildAge}</span>
                                                            </TableCell>
                                                            <TableCell sx={ styleChangeRoom.removeBorder }>{data?.childCount}</TableCell>
                                                            <TableCell sx={ styleChangeRoom.removeBorder }>
                                                                {data?.childCount >0 && <RemoveCircleIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} onClick={() => changeCounter(false, index, 'childCount')} />}
                                                                {data?.childCount === 0 && <RemoveCircleOutlineIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} />}
                                                            </TableCell>
                                                            <TableCell sx={ styleChangeRoom.removeBorder }>
                                                                {data?.childCount < 24 && <AddCircleIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} onClick={() => changeCounter(true, index, 'childCount')}/>}
                                                                {data?.childCount > 23 && <AddCircleOutlineIcon sx={{ fontSize: '30px', color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB' }} />}
                                                            </TableCell>
                                                        </TableRow>
                                                        {roomObj.length>1 && !(index === roomObj.length - 1) && 
                                                            <hr style={{border: '1px solid #FFFFFF', opacity: '0.2',marginTop: '20px', marginLeft:"12px", width:"200%"}} />
                                                        }
                                                        {index > 0 && index === roomObj.length - 1 && 
                                                            <Box component="div" style={{display:"flex", flexDirection:"row", width:"210%", justifyContent:"end"}}>
                                                                <Box 
                                                                    component="p" 
                                                                    onClick={removeRoom} 
                                                                    sx={{
                                                                        color:  '#fff', 
                                                                        fontSize: '14px',
                                                                        cursor: 'pointer', 
                                                                        float: 'right', 
                                                                        mt: '0px', 
                                                                        mr: '15px',
                                                                        backgroundColor: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB',
                                                                        borderRadius: '6pt',
                                                                        display: 'inline-block',
                                                                        padding: '10px',
                                                                        marginRight: '0px',
                                                                        marginTop: '10px'
                                                                    }}
                                                                >
                                                                    {t("widget","removeRoom")}
                                                                </Box>
                                                            </Box>
                                                        }
                                                    </TableBody>
                                                    
                                                )
                                            })}
                                        </Table>
                                    </TableContainer>
                                </div>
                                {
                                    roomObj.length < 5 && 
                                        <Box component="div" onClick={() => AddRoom()} sx={{...styleChangeRoom.addAnotherRoom, color:  booknowbuttoncolor ? booknowbuttoncolor : '#2952DD'}}>
                                            <AddCircleIcon sx={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                            <Box component="span" sx={{fontSize: '18px', paddingLeft: '5px' }}>{t("widget","addAnotherRoom")}</Box>
                                        </Box>
                                }
                                <Button sx={{ ...styleChangeRoom.done, backgroundColor:  booknowbuttoncolor ? booknowbuttoncolor : '#2952DD' }} onClick={()=>{updateData();handleClose()}} style={{textTransform:"uppercase"}}>{t("common","done")}</Button>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Box>
    )
}