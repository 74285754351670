{
    "common": {
        "mon": "Mon",
        "tue": "Tue",
        "wed": "Wed",
        "thu": "Thu",
        "fri": "Fri",
        "sat": "Sat",
        "sun": "Sun",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",        
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep": "Sep",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Dec",
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August", 
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December",
        "checkin": "Check-In",
        "checkout": "Check-Out",
        "room": "Room",
        "rooms": "Rooms",
        "adult": "Adult",
        "adults": "Adults",
        "edit": "Edit",
        "editCap": "EDIT",
        "delete":"Delete",
        "deleteCap":"DELETE",
        "localTime": "Local Time",
        "back": "BACK",
        "home": "HOME",
        "yes": "YES",
        "no": "NO",
        "left": "Left",
        "hour": "Hour",
        "hours": "Hours",
        "night": "night",
        "nights": "nights",
        "for": "for",
        "why": "Why",
        "get": "Get",
        "more": "More",
        "agree": "AGREE",
        "disagree": "DISAGREE",
        "basedOn": "based on",
        "error": "There was an error, please try again later",
        "error2": "Failed, try again!",
        "error3": "An Error Occurred!!",
        "error4": "An error occurred!! Please try again",
        "error5": "Sorry!! Some error occurred. Please try again after some time.",
        "hi": "Hi",
        "to": "to",
        "free": "Free",
        "per": "Per",
        "at": "at",
        "day": "Day",
        "days": "Days",
        "time": "Time",
        "of": "of",
        "photo": "Photo",
        "photos": "Photos",
        "done": "Done",
        "fullStay": "Full Stay",
        "date": "Date",
        "and": "and",
        "bookingDetail": "Booking Detail",
        "Back": "Back",
        "Home": "Home"
    },
    "userPopup":{
        "myAccount": "My Account",
        "welcome": "Welcome",
        "user": "User",
        "findMyBookings": "FIND MY BOOKINGS",
        "viewProfile": "VIEW PROFILE",
        "makeAReservarion": "MAKE A RESERVATION",
        "logout": "LOGOUT",
        "loginCap": "LOGIN",
        "login": "Login",
        "invalidCredentials": "Invalid credentials",
        "invalidEmail": "Invalid Email",
        "passwordIsRequired": "Password is required",
        "minChar8":"minimum 8 characters",
        "tooShort": "Too Short!",
        "tooLong": "Too Long!",
        "required": "Required",
        "emailInvalid":"Enter a valid email",
        "passwordInvalid":"Enter a valid password",
        "phNumInvalid": "Phone number is not valid",
        "signupCap": "SIGN UP",
        "signup": "Sign Up",
        "verifyText":"Verify that it's you",
        "verifyText2":"We sent a verification code to your email id",
        "verificationCode":"Verification Code",
        "didNotReceiveEmail": "Did not receive email",
        "clickToResend": "Click to resend",
        "verifyAndSignIn": "Verify and Sign in",
        "otpSentSuccessfully": "OTP sent successfully",
        "somethingWentWrong":  "Something went wrong, please try again",
        "otpError": "Please enter a valid number",
        "otpError2":"Please enter Numbers only",
        "otpError3": "Incorrect OTP, please try again",
        "otpError4": "OTP Expired, click Resend to try again",
        "otpError5": "Please try again",
        "forgetPassword": "Forget Password",
        "submitError": "User does not exist!",
        "userExistError": "User Already Exists",
        "checkYourEmail":  "Check your email",
        "passwordResetLinkSent": "We have sent a password reset link to",
        "noWorriesResetInstructions": "No worries, we'll send you reset instructions.",
        "enterYourEmail": "Enter your email",
        "resetMyPassword": "RESET MY PASSWORD",
        "setANewPassword": "Set a new password",
        "newPassword": "New Password",
        "confirmPassword": "Confirm Password",
        "confirmPasswordError": "Passwords must match",
        "backToLogin": "Back to Login",
        "dontHaveAccount": "Don't have an account?",
        "alreadyHaveAccount": "Already have an account?",
        "memberDiscount1": "You will get",
        "memberDiscount2": "member discount forever.",
        "discount1": "Manage all your Reservations from one place.",
        "discount2": "Sign up for personalized recommendations and tailored experiences!!",
        "discount3": "Join our community of happy guests and start planning your stay.",
        "leftGridText1": "Manage all your Reservations from one place",
        "leftGridText2": "Sign up for personalized recommendations and tailored experiences!!",
        "leftGridText3": "Join our community of happy guests and start planning your stay",
        "gridDiscountText1": "member discount on",
        "gridDiscountText2": "select properties forever",
        "gridDiscountText3": "Login for a tailored experience forever",
        "gridDiscountText4": "Get Exclusive Member Benefits",
        "findBookingError": "Your email and reservation id do not match",
        "verifyAndConfirmBooking":"Verify and confirm booking",
        "confirmEmailText": "We have sent a verification code to your e-mail address",
        "enterCodeText":"Please enter the verification code sent to you",
        "didNotRecieveEmail": "Did not received email?",
        "changeEmailText": "Click here to change the e-mail address",
        "verifyAndConfirm": "Verify and Confirm",
        "verified": "VERIFIED",
        "bookingExpired": "Booking Expired",
        "bookingExpiredText": "We are sorry, the booking id you are using got expired",
        "backToFindingBooking": "Back to Find Booking",
        "findMyBooking": "Find My Bookings",
        "bookingEmail": "Booking Email",
        "reservationId": "Reservation Id",
        "findBooking": "FIND BOOKING",
        "abandonmentCartDoneText1": "Thank you!",
        "abandonmentCartDoneText2":"Your cart details have been sent to the email. Continue your booking anytime.",
        "Recommended Currencies": "Recommended Currencies",
        "All currencies": "All currencies",
        "thisIsRequiredField": "This is required Field"
    },
    "widget":{
        "destination": "Destination",
        "enterDestination": "Enter Destination",
        "guests": "Guests",
        "selectedRate": "Selected Rate",
        "search": "Search",
        "ages": "Ages",
        "orAbove": "Or Above",
        "child": "Child",
        "children": "Children",
        "addAnotherRoom": "Add Another Room",
        "removeRoom": "Remove Room",
        "property": "Property",
        "city": "City",
        "country": "Country",
        "bookNow": "Book Now",
        "destinationIsRequired": "Destination is required",
        "checkInIsRequired": "Check In date is required",
        "checkOutIsRequired": "Check Out date is required"
    },
    "promotion": {
        "headingText": "Important Information for",
        "regularRate": "Regular Rate",
        "seniorCitizen": "Senior Citizen Rate",
        "agencyRate": "Agency Rate",
        "caaRate": "CAA/AAA Rate",
        "corporateRate": "Corporate Rate",
        "governmentRate": "Government/Military Rate",
        "promotionalSpecialRate": "Promotional/Special Rate",
        "regularRateDescription": "Regular rates are the most affordable rate currently offered for booking a hotel room.",
        "seniorCitizenRateDescription": "This is a discounted hotel rate extensively available to older adults, typically aged 60 or older.",
        "agencyRateDescription": "This is a discounted hotel rate offered that can be accessed through specific travel agents only fostering partnership and collaboration.",
        "caaRateDescription": "This is a special hotel rate offered to members of the AAA or CAA for exclusive savings.",
        "corporateRateDescription": "This is a discounted hotel rate extended to business travelers, typically negotiated between the hotel and corporate entity.",
        "governmentRateDescription": "This rate plan offers specific discounts for Government/Military personnel for booking a hotel room.",
        "contentSeniorCitizen": "Date of birth proof is mandatory at the time of check-in. Failure to produce relevant ID will result in charging prevailing fares.",
        "contentAgencyRate": "Make sure to have your booking confirmation and ID proof upon arrival. Failure to present these will result in standard rates being applied.",
        "contentPromotionalSpecialRate": "This is a discounted hotel rate offered for a limited time, often tied to specific events, seasons, or marketing campaigns.",
        "contentCaaRate": "Please ensure you have your CAA membership card or relevant identification at the time of check-in. Failure to produce relevant ID will result in charging prevailing fares.",
        "contentCorporateRate": "Proof of company ID or other official documentation is required at the time of check-in. Failure to produce relevant proof will result in charging prevailing fares.",
        "contentGovernmentRate": "Valid government or military ID must be provided upon check-in. Failure to provide the required identification will result in charges at the standard rate.",
        "CORP": "CORP",
        "AGEN": "AGEN",
        "PROM": "PROM",
        "apply": "APPLY",
        "Offer Code": "Offer Code",
        "Agency Code": "Agency Code",
        "Corporate Code": "Corporate Code",
        "All-Access Rate": "All-Access Rate"
    }
}