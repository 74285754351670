import { Alert, Slide, SlideProps, Snackbar } from '@mui/material'
import React from 'react'
import { useTranslation } from '../utility/function/useTranslations';

interface ToastProps {
    toastState: boolean;
    setToastState: (state: any) => void;
    toastMessage: string
}
type TransitionProps = Omit<SlideProps, 'direction'>;
const TransitionDown = (props: TransitionProps) => {
    return <Slide {...props} direction="down" />;
}
const Toast = ({ toastState, setToastState, toastMessage }: ToastProps) => {
    const { t } = useTranslation();
    const handleClose = () => {
        setToastState({
            toastState: false,
            toastMessage: t("widget","destinationRequired")
        })
    }

    return (
        <Snackbar
        open={toastState}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionDown}
        anchorOrigin={{ vertical:
            'top', horizontal:'center' }}
        >
            <Alert onClose={handleClose} severity="error">
                {toastMessage}
            </Alert>
        </Snackbar>
    )
}

export default Toast;