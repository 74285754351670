import en from '../../locales/en.json';
import fr from '../../locales/fr.json';
import ar from '../../locales/ar.json';

const translations:ITranslations = { en, fr, ar };

interface ITranslations {
  [languageCode: string]: {
    [key1: string]: {
      [key2: string]: string;
    };
  };
}

export const useTranslation = () => {
  const selectedLanguage = window.location.href.split("/").filter(item => item !== '')[2]
  const t = (key1: any, key2: any) => {
    return translations[selectedLanguage]?.[key1]?.[key2] || translations["en"]?.[key1]?.[key2];
  };
  return { t };
};